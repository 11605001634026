import React from 'react';
import withStyles from 'react-jss';
import profilePicture from "./images/profile.png";
import texts from './data/sections.json';
import pubs from './data/pubs.js';
import honors from './data/honors.json';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import SocialMediaBar from 'react-social-media-bar';

/**
 * Navigation bar for name, photo, etc.
 */
class Navbar extends React.Component {
  render() {
    const { classes } = this.props;
    return (
      <div className={classes.container}>
        <img className={classes.profilePhoto} src={profilePicture} alt="Me!"/>
        <div className={classes.profileInfo}>
          <p className={classes.name}>Neal Mangaokar</p>
          <p className={classes.profession}>Ph.D. candidate @UMich CSE</p>
        </div>
        <nav className={classes.buttons}><a href={require("./data/docs/CV.pdf")} target="_blank" rel="noopener noreferrer">CV</a></nav>
        <nav className={classes.buttons}><a href={"mailto: nealmangaokar@nealmangaokar.com"}>Contact</a></nav>
      </div>
    );
  }
}
let navbarStyles = {
  container: {
    display: "flex",
    "justify-content": "flex-start",
    "flex-wrap": "wrap"
  },
  profileInfo: {
    "flex-grow": 6
  },
  name: {
    font: {
      size: 22
    },
    margin: {
      top: "30px"
    }
  },
  profession: {
    margin: {
      bottom: "0px",
      top: "10px"
    }
  },
  profilePhoto: {
    height: "80px",
    width: "80px",
    margin: {
      right: "10px",
      top: "10px"
    },
    "border-radius": "15px",
    "border-style": "solid",
    "border-width": "thin"
  },
  buttons: {
    "flex-grow": 1,
    display: "flex",
    "justify-content": "flex-end",
    "align-items": "center",
    font: {
      size: 20
    },
    "& a": {
      "text-decoration": "none",
    },
  },
}
Navbar = withStyles(navbarStyles)(Navbar)

/**
 * Section component.
 */
class Section extends React.Component {
  render() {
    const { classes } = this.props;
    return (
      <div className={classes.container}>
        <p dangerouslySetInnerHTML={{ "__html": this.props.content }} />
      </div>
    );
  }
}

let sectionStyles = {
  container: {
    "line-height": "150%",
  }
}
Section = withStyles(sectionStyles)(Section)

/**
 * Section header component.
 */
class SectionHeader extends React.Component {
  render() {
    const { classes } = this.props;
    const { content } = this.props
    return (
      <div className={classes.container}>
        <strong>{content}</strong>
      </div>
    )
  }
}
let sectionHeaderStyles = {
  container: {
    "align-self": "flex-start",
    font: {
      size: 20
    },
    margin: {
      top: "20px"
    }
  },

}
SectionHeader = withStyles(sectionHeaderStyles)(SectionHeader)

class Publications extends React.Component {
  render() {
    let { classes } = this.props;
    let pubsList = [];
    pubs.forEach((pub) => {
      pubsList.push(
        <li key={pub.title}>
          <p>
            <strong>
              {pub.filename && <a href={pub.filename} target="_blank" rel="noopener noreferrer">{pub.title}</a>}
              {!pub.filename && pub.title}
            </strong>
            <br />
            {pub.authors}
            <br />
            {pub.conference_info} {pub.presentation_link && <a href={pub.presentation_link} target="_blank" rel="noopener noreferrer"> [Presentation]</a>}
            {pub.code && <a href={pub.code} target="_blank" rel="noopener noreferrer">[Code]</a>}
          </p>
        </li>
      );
    });
    return <ul className={classes.list}>{pubsList}</ul>;
  }
}

let publicationsStyles = {
  list: {
    "list-style": "none",
    padding: 0,
    font: {
      "line-height": "150%",
    },
    margin: 0
  },
}
Publications = withStyles(publicationsStyles)(Publications)

class Honors extends React.Component {
  generateYearlyAwardsList(awardsListStrings) {
    const { classes } = this.props;
    let awardsList = [];
    awardsListStrings.forEach(awardString => {
      awardsList.push(<li key={awardString}>{awardString}</li>);
    });
    return <ul className={classes.list}>{awardsList}</ul>;
  }

  render() {
    const { classes } = this.props;
    let awardsLists = [];
    let years = Object.keys(honors).sort().reverse();
    years.forEach(year => {
      awardsLists.push(
        <div key={year} className={classes.listContainer}>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
            <strong>{year}</strong>
            </AccordionSummary>
            <AccordionDetails>
              <hr/>
              {this.generateYearlyAwardsList(honors[year])}
            </AccordionDetails>
          </Accordion>
        </div>
      );
    });
    return (
      <div className={classes.container}>{awardsLists}</div>
    );
  }
}

let honorsStyles = {
  list: {
    "list-style": "none",
    padding: 0,
    font: {
      "line-height": "150%",
    },
  },
  listContainer: {
    margin: {
      top: "20px"
    },
    width: "500px"
  },
  container: {
    display: "flex",
    "flex-wrap": "wrap",
    "justify-content": "space-between"
  }
}
Honors = withStyles(honorsStyles)(Honors)

/**
 * Main app component.
 */
class App extends React.Component {
  render() {
    const { classes } = this.props;
    return (
      <div className={classes.container}>
        <Navbar />
        <hr className={classes.divider} />
        <Section content={texts.about} />
        <Section content={texts.recent} />
        <SectionHeader content={"Publications and Preprints"} id={"publications"} />
        <hr className={classes.divider} />
        <Publications />
        <SectionHeader content={"Honors"} />
        <hr className={classes.divider} />
        <Honors/>
        <br/>
      </div>
    );
  }
}

/** 
 * Note about divider styles:
 * Need these because flexbox will fuck with horizontal rules because they have no content so:
 * a) auto margins turn it into 0 width
 * b) parent align-items: center aligns the no-content item to center
 * See: https://stackoverflow.com/questions/50184608/why-does-flex-give-hr-element-width-of-0
 * */
let appStyles = {
  container: {
    display: "flex",
    "flex-direction": "column",
    "align-items": "stretch",
    "justify-content": "flex-start",
    width: "75%",
  },
  divider: {
    margin: {
      top: 10,
      left: 0,
      right: 0,
      bottom: 0
    },
    "align-self": "stretch",
  }
}
export default withStyles(appStyles)(App);
